import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Box, Label, Input, Textarea, Button, Message, Spinner, Text} from 'theme-ui'

const PageTerms = props => {

  return (
    <Layout {...props}>
      <Seo title='Terminos' />
      <Divider />
      
     
      <Stack effectProps={{ effect: 'fadeInDown' }}>
      <PageTitle
          header='Términos y Condiciones de Uso de Cashfy'
          subheader='

          Fecha de vigencia: 25/09/2022
          <br><br>
Te damos la bienvenida a Cashfy ("Sitio web", "nosotros" o "nuestro"). Estos Términos y Condiciones de Uso ("Términos") establecen las condiciones bajo las cuales puedes acceder y utilizar nuestro sitio web. Al utilizar este sitio web, aceptas cumplir y estar sujeto a estos Términos. Si no estás de acuerdo con estos Términos, te rogamos que no utilices el sitio web.
<br><br>
1. Uso Adecuado
<br><br>
a. El contenido proporcionado en Cashfy tiene fines informativos y educativos relacionados con las finanzas en Argentina. No constituye asesoramiento financiero, legal o de inversión. No nos hacemos responsables de ninguna decisión tomada en función de la información proporcionada en este sitio.
<br><br>
b. Debes tener al menos 18 años de edad o la mayoría de edad legal en tu jurisdicción para utilizar este sitio web.
<br><br>
2. Propiedad Intelectual
<br><br>
a. Todo el contenido del sitio web, incluyendo textos, imágenes, logotipos, videos y gráficos, está protegido por derechos de autor y otros derechos de propiedad intelectual. No puedes reproducir, distribuir o utilizar de ninguna manera el contenido sin nuestro permiso expreso por escrito.
<br><br>
3. Privacidad
<br><br>
a. La información personal que proporciones al utilizar este sitio web se rige por nuestra Política de Privacidad, que se encuentra disponible en el sitio. Al utilizar el sitio web, aceptas nuestras prácticas de recopilación y uso de datos de acuerdo con la Política de Privacidad.
<br><br>
4. Enlaces a Terceros
<br><br>
a. Cashfy puede contener enlaces a sitios web de terceros. No tenemos control sobre el contenido o las políticas de privacidad de estos sitios y no nos hacemos responsables de ellos.
<br><br>
5. Cambios en los Términos
<br><br>
a. Nos reservamos el derecho de modificar estos Términos en cualquier momento. Los cambios entrarán en vigor al ser publicados en este sitio web. Te recomendamos revisar periódicamente estos Términos para estar informado de las actualizaciones.
<br><br>
6. Limitación de Responsabilidad
<br><br>
a. En la medida máxima permitida por la ley, no seremos responsables por ningún daño directo, indirecto, incidental, especial, consecuente o punitivo, ni por pérdidas de beneficios, ingresos, datos o pérdidas comerciales relacionadas con el uso de este sitio web.
<br><br>
7. Jurisdicción y Ley Aplicable
<br><br>
a. Estos Términos se rigen por las leyes de la República Argentina y cualquier disputa que surja de estos Términos estará sujeta a la jurisdicción exclusiva de los tribunales argentinos.
<br><br>
Si tienes alguna pregunta sobre estos Términos y Condiciones, por favor, contáctanos a través de [información de contacto].
<br><br>
Gracias por utilizar Cashfy.







          '
        />
      </Stack>

    </Layout>
  )
}

export default PageTerms
